<template>
    <div>
        <Header :customstyle="{ background: '#0C1913' }">
            <template slot="title">海报素材</template>
        </Header>
        <div class="content">
            <div class="cell" v-for="(item, index) in listdata" :key="index">
                <div class="leftView">
                    <div class="txt van-multi-ellipsis--l3">
                        {{ item.name }}
                    </div>
                    <div  class="van-multi-ellipsis--l3" v-if="item.desc" v-html="item.desc"  @click="copyLink($event)" id="tag">
                    </div>
                    <div v-if="item.desc.length>50" style="margin: 10px 0;color: #27CC7F;" @click="showMore($event)">展开</div>
                    <div class="time">
                        {{item.utime}}
                    </div>
                </div>
                <div class="rightView" v-if="item.images_url">
                    <a :href="item.images_url" download>
                        <img class="img" :src="item.images_url" />
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        getBanner,
    } from "@/request/api";
    import Header from "../../components/header.vue";
    import Clipboard from 'clipboard'
    import { Toast } from 'vant'
    import $ from 'jquery'
    export default {
        components: {
            Header
        },
        data() {
            return {
                listdata: [
                ],
                refreshing: false,
                loading: false,
                finished: false,
                currentPage:1
            }
        },
        activated() {
            this.getDataList();
        },
        methods: {
            showMore(event){
                var toggle = event.currentTarget;
                if($(toggle).prev().css('display')=='block'){
                    $(toggle).prev().css('display','-webkit-box')
                    $(toggle).text('展开')
                }else{
                    $(toggle).prev().css('display','block')
                    $(toggle).text('折叠')
                }
            },
            //复制
            copyLink(event){
                var _this = this;
                var toggle = event.currentTarget;
                let clipboard = new Clipboard('#tag', {
                    text: function () {
                        return $(toggle).text();
                    }
                })
                clipboard.on('success', e => {
                    Toast('复制成功');
                    // 释放内存
                    clipboard.destroy()
                })
                clipboard.on('error', e => {
                    Toast('复制失败');
                    clipboard.destroy()
                })
            },
            clickDetail (queryid) {
                console.log(queryid)
                this.$router.push({
                    path:'/home/mine/operation/operatedetail',
                    query: {
                        id:queryid
                    }
                })
            },
            getDataList() {
                getBanner({ type: "3" }).then((res) => {
                    this.listdata = res.data;
                });
            }
        }
    }
</script>

<style lang="scss" scoped>
    .content {
        background: #0C1913;
        min-height: 100vh;
        padding: 0 32px;
        .cell {
            padding: 32px 0px;
            border-bottom: 1px solid ;
            color: rgba(139,166,149,1);
            display: flex;
            justify-content: space-between;
            .leftView {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .txt {
                    color: rgba(214,255,227,1);
                    font-size: 32px;
                    font-weight: 700;
                    font-family: "PingFang SC";
                }
                .time {
                    color: rgba(139,166,149,1);
                    font-size: 24px;
                    font-weight: 400;
                    font-family: "PingFang SC";
                }
            }
            .rightView {
                .img {
                    width: 240px;
                    height: 240px;
                    border-radius: 16px;
                    opacity: 1;
                    background: rgba(217,217,217,1);
                }
            }
        }
    }

</style>